export const PAYMENT_METHODS = {
	CARD: 'card',
	CASH: 'cash',
	WALLET: 'wallet',
	BANK_TRANSFER: 'bank_transfer',
	TABBY: 'tabby',
	TAMARA: 'tamara',
	LOYALTY_PROGRAM: 'loyalty_program',
	HYPERPAY: 'hyperpay',
};

export const GATWAY_METHOD_TYPES = {
	QITAF_LOYALTY_PROGRAM: 'qitaf_loyalty_program',
	HYPERPAY: 'hyperpay',
	CHECKOUT: 'checkout',
	CORPORATE_LIMIT: 'corporate_limit',
};

export const METHOD_TYPES = {
	APPLE_PAY: 'applepay',
	TELGANI_PAY: 'telganipay',
	QITAF: 'qitaf',
};

export const CARD_SCHEME = {
	VISA: 'Visa',
	MASTER_CARD: 'Mastercard',
};
