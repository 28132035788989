import { ADMIN, COMPANY_MANAGER, OFFICE_MANAGER, ADMINS } from 'helpers';

export const getOption = (type, booking) => {
	return booking.options?.find((option) => option.type === type);
};

export const FIELDS_ACCESSORS = {
	LICENSE: 'license',
	PAYMENT_METHOD: 'payment_method',
	PAYMENT_METHODS: 'payment_methods',
	OPTIONS_PRICES: 'options_prices',
	PICK_UP_ADDRESS: 'pick_up_address',
	OPTIONS: 'options',
	BOOKING_STATUS: 'booking_status',
	FULL_NAME: 'full_name',
	FULL_ARABIC_NAME: 'customer.full_arabic_name',
	CONTRACT_DATES: 'contract_dates',
	EXTENDED_DROP_DATE: 'extended_drop_date',
	DISCOUNT: 'DISCOUNT',
	WALLET: 'wallet',
	NEW_DROP_DATE: 'new_drop_date',
	TOTAL_EXTENSION_AMOUNT: 'total_extension_amount',
	SUBSCRIPTION_INVOICE_PAYMENT_METHOD: 'subscription_invoice_payment_method',
	INVOICE_OPTIONS: 'invoice_options',
	VIEW_ATTACHMENT: 'view_attachment',
	DROP_DATE: 'drop_date',
	DROP_DATE_AFTER_EARLY_RETURN: 'drop_date_after_early_return',
	DAYS_AFTER_EARLY_RETURN: 'days_after_early_return',
	DAYS: 'days',
	OLD_DELIVERY_INFORMATION: 'old_delivery_information',
	PAYER: 'payer',
	DIVIDED_TOTAL_GROSS_AMOUNT: 'divided_total_gross_amount',
};

export const FIELDS_CUSTOMER = [
	{
		accessor: FIELDS_ACCESSORS.FULL_NAME,
		label: 'bookings.preview_booking.customer.full_name',
	},
	{
		accessor: FIELDS_ACCESSORS.FULL_ARABIC_NAME,
		label: 'bookings.preview_booking.customer.full_arabic_name',
	},
	{
		accessor: 'customer.phone',
		label: 'bookings.preview_booking.customer.phone',
	},
	{
		accessor: 'customer.email',
		label: 'bookings.preview_booking.customer.email_address',
	},
	{
		accessor: 'customer.birth_date',
		label: 'bookings.preview_booking.customer.birth_date',
	},
	{
		accessor: 'customer.card_holder_name',
		label: 'bookings.preview_booking.customer.card_holder_name',
	},
	{
		accessor: 'customer.license_number',
		label: 'bookings.preview_booking.customer.license_number',
	},
	{
		accessor: 'customer.license_expire_date',
		label: 'bookings.preview_booking.customer.license_expire_date',
	},
	{
		accessor: FIELDS_ACCESSORS.LICENSE,
		label: 'bookings.preview_booking.customer.license',
	},
	{
		accessor: FIELDS_ACCESSORS.WALLET,
		label: 'bookings.preview_booking.customer.wallet',
	},
	{
		accessor: 'customer.statistics.accepted_booking_count',
		label: 'bookings.preview_booking.customer.accepted_bookings_no',
	},
];

export const FIELDS_NEW_COMPANY = [
	{
		accessor: 'company.name',
		label: 'bookings.preview_booking.company.name',
	},
	{
		accessor: 'office.name',
		label: 'bookings.preview_booking.company.office_name',
	},
	{
		accessor: 'office.address',
		label: 'bookings.preview_booking.company.office_address',
	},
];

export const FIELDS_OLD_COMPANY = [
	{
		accessor: 'company_name',
		label: 'bookings.preview_booking.company.name',
	},
	{
		accessor: 'office_name',
		label: 'bookings.preview_booking.company.office_name',
	},
	{
		accessor: 'office_address',
		label: 'bookings.preview_booking.company.office_address',
	},
];

export const FIELDS_PERIOD = [
	{
		accessor: FIELDS_ACCESSORS.DAYS,
		label: '',
	},
	{
		accessor: FIELDS_ACCESSORS.DAYS_AFTER_EARLY_RETURN,
		label: '',
	},
	{
		accessor: 'period.pick_date',
		label: 'bookings.preview_booking.period.pick_date',
	},
	{
		accessor: FIELDS_ACCESSORS.DROP_DATE,
		label: '',
	},
	{
		accessor: FIELDS_ACCESSORS.DROP_DATE_AFTER_EARLY_RETURN,
		label: '',
	},
	{
		accessor: FIELDS_ACCESSORS.EXTENDED_DROP_DATE,
		label: '',
	},
	{
		accessor: 'period.pick_time',
		label: 'bookings.preview_booking.period.pick_time',
	},
	{
		accessor: FIELDS_ACCESSORS.CONTRACT_DATES,
		label: '',
	},
];

export const FIELDS_CAR = [
	{
		accessor: 'item.name',
		label: 'bookings.preview_booking.car.manufacturer',
	},
	{
		accessor: FIELDS_ACCESSORS.OPTIONS,
		label: '',
	},
];

export const FIELDS_INVOICE = [
	{
		accessor: FIELDS_ACCESSORS.PAYMENT_METHODS,
		label: 'bookings.preview_booking.invoice.payment_method',
	},
	{
		accessor: FIELDS_ACCESSORS.PAYER,
		label: '',
	},
	{
		accessor: 'item.amount_nett',
		label: 'bookings.preview_booking.invoice.nett_price',
		withCurrency: true,
	},
	{
		accessor: 'period.amount_nett',
		label: 'bookings.preview_booking.invoice.total_nett_price',
		withCurrency: true,
	},
	{
		accessor: FIELDS_ACCESSORS.INVOICE_OPTIONS,
		label: '',
	},
	{
		accessor: 'subtotal.amount_tax_diff',
		label: 'bookings.preview_booking.invoice.total_vat_price',
	},
	{
		accessor: 'subtotal.amount_gross',
		label: 'bookings.preview_booking.invoice.total_gross_price',
	},
	{
		accessor: FIELDS_ACCESSORS.DIVIDED_TOTAL_GROSS_AMOUNT,
	},
	{
		accessor: FIELDS_ACCESSORS.DISCOUNT,
		label: '',
	},
	{
		accessor: 'total.amount_gross',
		label: 'bookings.preview_booking.invoice.total_discount_gross_price',
		withCurrency: true,
	},
];

export const EXTENSION_DIALOG_FIELDS = [
	{
		accessor: FIELDS_ACCESSORS.NEW_DROP_DATE,
		label:
			'bookings.preview_booking.booking_info.extension_info.new_drop_off_date',
	},
	{
		accessor: FIELDS_ACCESSORS.TOTAL_EXTENSION_AMOUNT,
		label:
			'bookings.preview_booking.booking_info.extension_info.total_extension_amount',
	},
];

export const FIELDS_URGENT_STATUS_PREVIEW = (lng) => [
	{
		accessor: 'urgentReasons[0].status',
		label: 'bookings.preview_booking.booking_info.urgent_status',
	},
	{
		accessor: `urgentReasons[0].name[${lng}]`,
		label: 'bookings.preview_booking.booking_info.urgent_reason',
	},
];

export const DELIVERY_INFORMATION_FIELDS = [
	{
		accessor: FIELDS_ACCESSORS.PICK_UP_ADDRESS,
	},
];

export const OLD_DELIVERY_INFORMATION_FIELDS = [
	{
		accessor: FIELDS_ACCESSORS.OLD_DELIVERY_INFORMATION,
	},
];

export const PERMISSION_RATE_OFFICE = [ADMIN];
export const PERMISSION_CUSTOMER_COMPENSATION = [ADMIN];
export const PERMISSION_URGENT_ASSIGN = [ADMIN];
export const PERMISSION_DELIVERY_INFORMATION = [
	ADMIN,
	COMPANY_MANAGER,
	OFFICE_MANAGER,
];

export const PERMISSION_RATE_CUSTOMER = [
	ADMIN,
	COMPANY_MANAGER,
	OFFICE_MANAGER,
];

export const PERMISSION_ADD_ADMIN_COMMENT = ADMINS;

export const PERMISSION_PARTIAL_PAYMENT = [ADMIN];

export const PERMISSION_CUSTOMER_WALLET_PREVIEW = [ADMIN];

export const ADMIN_ONLY_INVOICE_OPTIONS = {
	FAST_DELIVERY_AT_AIRPORT: 'fast_delivery_at_airport',
};
